<template>
    <div>
        <b-row>
            <b-col cols="4">
                <label>Checklist <span class="text-red">*</span></label>
                <b-input-group>
                    <b-form-input v-model="selectedScheduledChecklist.checkList" :disabled="true"></b-form-input>
                    <b-input-group-append>
                        <b-button size="sm"  text="Button" variant="secondary" @click="openChecklistSearchModal">Search</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
            <b-col cols="4">
                <label>Scheduled Due <span class="text-red">*</span></label>
                <b-form-datepicker :min="minDate" v-model="selectedScheduledChecklist.dueDate"></b-form-datepicker>
            </b-col>
            <b-col cols="4">
                <label>Schedule according to <span class="text-red">*</span></label>
                <b-form-select v-model="selectedScheduledChecklist.scheduleFor" :disabled="state === 'loading'" @change="newScheduleFor()">
                    <b-form-select-option v-for="(item, index) in scheduleForList" :key="index" :value="item">{{item}}</b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>

        <hr class="mx-3" />

        <div v-if="selectedScheduledChecklist.scheduleFor === 'Individual'">
            <b-row>
                <b-col class="text-right">
                    <b-button variant="primary" @click="openAddUserModal()" squared>Add User</b-button>
                </b-col>
            </b-row>
            
            <b-row>
                <b-col class="12">
                    <b-table striped hover 
                            :items="scheduledForTableData.dataSource" 
                            :fields="scheduledForTableData.tableColumns"
                            :busy="scheduledForTableData.isLoading" 
                            @row-clicked="chooseUser"
                            :per-page="scheduledForTableData.perPage"
                            id="userSearch"
                            :current-page="scheduledForTableData.currentPage"
                            sort-icon-left>

                        <template #table-busy>
                            <div class="text-center my-2">
                                <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                            </div>
                        </template>

                        <template #cell(actions)="row">
                            <b-row align-v="center" align-h="end">
                                <b-button @click="removeUser(row.item)" size="sm" variant="red">
                                    Remove
                                </b-button>
                            </b-row>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
          
            <b-row align-h="center" >
                <b-pagination
                    v-model="scheduledForTableData.currentPage"
                    :total-rows="scheduledForTableDataRows"
                    :per-page="scheduledForTableData.perPage"
                    aria-controls="userSearch"
                ></b-pagination>
            </b-row>
        </div>

        <div v-if="selectedScheduledChecklist.scheduleFor === 'Supervisor'">
            <b-row>
                <b-col cols="4">
                    <label>Supervisor</label>
                    <b-input-group>
                        <b-form-input v-model="selectedScheduledChecklist.scheduleForItems[0].name" :disabled="true"></b-form-input>
                        <b-input-group-append>
                            <b-button size="sm"  text="Button" variant="secondary" @click="openAddSupervisorModal">Search</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
                <b-col cols="8">
                    <b-alert show variant="info">Please note that this is not for the supervisor alone. Scheduling per supervisor will schedule the Checklist or Toolbox
                        Talk for all those under the supervisor.
                    </b-alert>
                </b-col>
            </b-row>
        </div>

        <div v-if="selectedScheduledChecklist.scheduleFor === 'Office/Region'">
            <b-row>
                <b-col cols="4">
                    <label>Filter by</label>
                    
                    <b-form-radio-group v-model="filterRegion"  @change="changeOffice()">
                        <b-form-radio :value="true">Region</b-form-radio>
                        <b-form-radio :value="false">Office</b-form-radio>
                    </b-form-radio-group>
                </b-col>
                <b-col cols="4">
                    <label>{{filterRegion === true ? 'Region' : 'Office'}}</label>
                    <b-form-select v-if="filterRegion" v-model="selectedScheduledChecklist.scheduleForItems[0]" :disabled="state === 'loading'">
                        <b-form-select-option v-for="(item, index) in regions" :key="index" :value="item">{{item.name}}</b-form-select-option>
                    </b-form-select>
                    <b-form-select v-if="!filterRegion" v-model="selectedScheduledChecklist.scheduleForItems[0]" :disabled="state === 'loading'">
                        <b-form-select-option v-for="(item, index) in offices" :key="index" :value="item">{{item.name}}</b-form-select-option>
                    </b-form-select>
                </b-col>
            </b-row>
        </div>
        
        <div v-if="selectedScheduledChecklist.scheduleFor === 'Company'">
            <b-row>
                <b-col class="text-right">
                    <b-button variant="primary" squared @click="selectAll" class="ml-2" size="sm">Select All</b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Select Business Units to schedule for">
                        <b-form-checkbox-group
                        v-model="selectedScheduledChecklist.scheduleForItems">
                        <b-row class="m-0 p-0">
                            <b-col cols="2" class="m-0 p-0" v-for="(item, index) in companies" :key="index">
                                <b-form-checkbox :value="item">{{item.name}}</b-form-checkbox>
                            </b-col>
                        </b-row>
                        
                        </b-form-checkbox-group>
                    </b-form-group>
                </b-col>
            </b-row>
        </div>

            <b-row>
            <b-col class="text-right">
                <b-button variant="red" class="ml-2" squared @click="navigateBack" >Cancel</b-button>
                <b-button variant="primary" squared @click="saveSchedule" class="ml-2">Save</b-button>
            </b-col>
        </b-row>
        
        <b-modal id="search-checklist-modal" size="xl" hide-footer no-header @close="closeChecklistSearchModal">
            <b-row>
                <b-col cols="4">
                    <label>Checklist Name</label>
                    <b-form-input v-model="searchChecklist.description"></b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Date Created From</label>
                    <b-form-datepicker v-model="searchChecklist.dateCreatedFrom"></b-form-datepicker>
                </b-col>
                <b-col cols="4">
                    <label>Date Created To</label>
                    <b-form-datepicker v-model="searchChecklist.dateCreatedTo"></b-form-datepicker>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="4">
                    <label>Type</label>
                    <b-form-select v-model="searchChecklist.typeId" :disabled="state === 'loading'">
                        <b-form-select-option v-for="(item, index) in checklistTypes" :key="index" :value="item.id">{{item.description}}</b-form-select-option>
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button variant="red" squared class="mr-2" @click="clearChecklistFilter()">Cancel</b-button>
                    <b-button variant="primary" squared @click="searchChecklistResults()">Search</b-button>
                </b-col>
            </b-row>
            <hr class="mx-3" />
            <b-row>
                <b-col>
                    <b-table striped hover :items="tableData.dataSource" :fields="tableData.tableColumns" 
                        :busy="tableData.isLoading" @row-clicked="selectChecklist" 
                        :per-page="tableData.perPage"
                        :current-page="tableData.currentPage"
                        sort-icon-left>
                        <template #table-busy>
                            <div class="text-center my-2">
                                <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                            </div>
                        </template>

                        <template #cell(disabled)="row">
                            <b-row align-v="center">
                                <span class="mr-auto">{{row.item.disabled ? 'Unavailable' : 'Available'}}</span>
                            </b-row>
                        </template>
                        
                        <template #cell(checkListDate)="row">
                            <b-row align-v="center">
                                <span class="mr-auto">{{row.item.checkListDate | dateTimeFilter}}</span>
                            </b-row>
                        </template>

                        
                        <template #cell(actions)="row">
                            <b-row align-v="center" align-h="end">
                                <b-button @click="selectChecklist(row.item)" class="btn-icon" size="sm">
                                    <b-icon-chevron-right></b-icon-chevron-right>
                                </b-button>
                            </b-row>
                        </template>

                    </b-table>
                </b-col>
            </b-row>
            <b-row align-h="center" >
                    <b-pagination
                    v-model="tableData.currentPage"
                    :total-rows="rows"
                    :per-page="tableData.perPage"
                    ></b-pagination>
            </b-row>
            <b-row>
                <b-col class="text-right">
                    <b-button variant="red" class="mr-2" squared @click="closeChecklistSearchModal">Cancel</b-button>
                </b-col>
            </b-row>
        </b-modal>
        
        <b-modal id="search-user-modal" size="xl" hide-footer no-header @close="closeSearchUserModal">
            
            <b-row>
                <b-col cols="4">
                    <label>Name</label>
                    <b-form-input v-model="user.name"></b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Surname</label>
                    <b-form-input v-model="user.surname"></b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Email</label>
                    <b-form-input v-model="user.email"></b-form-input>
                </b-col>
            </b-row>
            <hr class="mx-3">
            <b-row>
                <b-col class="text-right">
                    <b-button variant="red" class="ml-2" squared @click="clearUsersFilter" >Cancel</b-button>
                    <b-button variant="primary" squared @click="doSearchUsers" class="ml-2">Search</b-button>
                </b-col>
            </b-row>
            
            <b-row>
                <b-col class="12">
                    <b-table striped hover 
                            :items="tableDataUsers.dataSource" 
                            :fields="tableDataUsers.tableColumns"
                            :busy="tableDataUsers.isLoading" 
                            @row-clicked="chooseUser"
                            :per-page="tableDataUsers.resultsPerPage"
                            id="userSearch"
                            :current-page="tableDataUsers.currentPage"
                            sort-icon-left>

                        <template #table-busy>
                            <div class="text-center my-2">
                                <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                            </div>
                        </template>

                        <template #cell(actions)="row">
                            <b-row align-v="center" align-h="end">
                                <b-button @click="chooseUser(row.item)" size="sm" class="btn-icon">
                                <b-icon-chevron-right></b-icon-chevron-right>
                                </b-button>
                            </b-row>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
          
            <b-row align-h="center" >
                <b-pagination
                    v-model="tableDataUsers.currentPage"
                    :total-rows="userRows"
                    :per-page="tableDataUsers.resultsPerPage"
                    aria-controls="userSearch"
                ></b-pagination>
            </b-row>
            <b-row>
                <b-col class="text-right">
                    <b-button variant="red" class="mr-2" squared @click="closeSearchUserModal">Cancel</b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import { bus } from '../main'
import { mapActions, mapState } from 'vuex'

export default {
    name: 'Logger',
    data: () => ({
        state: 'initiliazing',
        scheduleForList: [],
        checklistTypes: [],
        minDate: null,
        filterRegion: false,
        searchChecklist:{
            description: null,
            dateCreatedFrom: null,
            dateCreatedTo: null,
            typeId: null
        },
        tableData: {
            totalRecords: 10,
            perPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Procedure Number',
                    key: 'procedureNumber',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Checklist Name',
                    key: 'description',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Type',
                    key: 'type',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Date Created',
                    key: 'checkListDate',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Created By',
                    key: 'createdByUser',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: ''
                }
            ]
        },
        offices: [],
        regions: [],
        companies: [],
        
        user: {
            name: null,
            surname: null,
            email: null,
            roleID: 0
        },
        tableDataUsers: {
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                label: 'Name',
                key: 'name',
                sortable: true,
                tdClass: '',
                },
                {
                label: 'Surname',
                key: 'surname',
                sortable: true,
                tdClass: '',
                },
                {
                label: 'Email',
                key: 'email',
                sortable: true,
                tdClass: '',
                },
                {
                label: 'Contact Number',
                key: 'contactNumber',
                sortable: true,
                tdClass: '',
                },
                {
                label: '',
                key: 'actions',
                sortable: false,
                tdClass: ''
                }
            ]
        },
        
        scheduledForTableData: {
            totalRecords: 10,
            perPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Name',
                    key: 'name',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: ''
                }
            ]
        },
    }),
    created(){
        this.getScheduledChecklistsForList()
        .then((res) => {
            this.scheduleForList = res.data
        })

        this.minDate = new Date()
        this.minDate.setDate(this.minDate.getDate() + 1);

        this.getScheduleCompanies()
        .then((res) => {
            this.offices = res.data.offices
            this.regions = res.data.regions
        })

        this.getScheduleCompaniesList()
        .then((res) => {
            this.companies = res.data
        })

        if (this.selectedScheduledChecklist === null) {
            this.$router.push({path: '/checklist/schedule/search'})
        }

        if (this.selectedScheduledChecklist.scheduleFor === 'Individual') {
            this.scheduledForTableData.dataSource = this.selectedScheduledChecklist.scheduleForItems
            this.scheduledForTableData.isLoading = false
        } 
        
        if (this.selectedScheduledChecklist.scheduleFor === 'Office') {
            this.selectedScheduledChecklist.scheduleFor = 'Office/Region'
            this.filterRegion === false
        }

        if (this.selectedScheduledChecklist.scheduleFor === 'Region') {
            this.selectedScheduledChecklist.scheduleFor = 'Office/Region'
            this.filterRegion = true
        }
    
    },
    methods:{
        ...mapActions('notificationService', ['addWarningMessage', 'clearMessage']),
        ...mapActions(['getScheduledChecklistsForList', 'getScheduleCompaniesList', 'getChecklistTypes', 'searchChecklists', 'searchUsersRequest', 'getScheduleCompanies']),
        changeOffice(){
            this.selectedScheduledChecklist.scheduleForItems = []
        },
        saveSchedule(){
            let error = false
            this.clearMessage()
            if (this.selectedScheduledChecklist.checkListId === null) {
                this.addWarningMessage(`Please enter a checklist to schedule for.`)
                error = true
            }
            //console.log('duedate', this.selectedScheduledChecklist.dueDate)
            if (this.selectedScheduledChecklist.dueDate === null || this.selectedScheduledChecklist.dueDate === undefined) {
                this.addWarningMessage(`Please enter a due date.`)
                error = true
            }
            if (this.selectedScheduledChecklist.scheduleFor === null || this.selectedScheduledChecklist.scheduleFor === undefined) {
                this.addWarningMessage(`Please enter a group to schedule this for.`)
                error = true
            }

            if (!error) {
                if (this.selectedScheduledChecklist.scheduleFor === 'Office/Region') {
                    this.selectedScheduledChecklist.scheduleFor = this.filterRegion === true ? 'Region' : 'Office'
                }
                bus.$emit('saveSchedule')
            }
        },
        navigateBack(){
            this.$router.back()
        },
        removeUser(rowItem){
            let itemIndex = this.selectedScheduledChecklist.scheduleForItems.findIndex(element => element === rowItem)
            this.selectedScheduledChecklist.scheduleForItems.splice(itemIndex, 1)
        },
        newScheduleFor(){
            if (this.selectedScheduledChecklist.scheduleFor === 'Supervisor') {
                this.selectedScheduledChecklist.scheduleForItems = [{name: null }]
            } else {
                this.selectedScheduledChecklist.scheduleForItems = []
            }
            this.scheduledForTableData.dataSource = this.selectedScheduledChecklist.scheduleForItems
            this.scheduledForTableData.isLoading = false
        },
        chooseUser(rowItem){
            this.state = 'loading'
            if (this.selectedScheduledChecklist.scheduleFor === 'Supervisor') {
                this.selectedScheduledChecklist.scheduleForItems[0] = { id: rowItem.userId, name: rowItem.name + ' ' + rowItem.surname }
            } else {
                this.scheduledForTableData.isLoading = true
                this.selectedScheduledChecklist.scheduleForItems.push({
                    id: rowItem.userId,
                    name: rowItem.name + ' ' + rowItem.surname
                })
                this.scheduledForTableData.isLoading = false
            }
            this.state = 'show'
            this.closeSearchUserModal()
        },
        closeSearchUserModal(){
            this.$bvModal.hide('search-user-modal')
        },
        openAddUserModal(){
            this.$bvModal.show('search-user-modal')
            this.user.roleId = null
            this.doSearchUsers()
        },
        openAddSupervisorModal(){
            this.$bvModal.show('search-user-modal')
            this.user.roleId = 1
            this.doSearchUsers()
        },
        clearUsersFilter(){
            this.user = {
                name: null,
                surname: null,
                email: null
            }
        },
        doSearchUsers(){
            this.tableDataUsers.isLoading = true
      
            let request = []
            
            if (this.user.name != null) {
                request.push({'key': 'name', 'value': this.user.name})
            }
            if (this.user.surname != null) {
                request.push({'key': 'surname', 'value': this.user.surname})
            }
            if (this.user.email != null) {
                request.push({'key': 'email', 'value': this.user.email})
            }
            if (this.user.roleId != null) {
                request.push({'key': 'fkroleId', 'value': this.user.roleId})
            }
            
            console.log(' this.selectedScheduledChecklist', this.selectedScheduledChecklist)
            request.push({'key': 'checklistId', 'value': this.selectedScheduledChecklist.checkListId})
            

            this.$store.commit('setUserSearchRequest', request)
            
            this.searchUsersRequest()
            .then((request) => {
                this.tableDataUsers.dataSource = request.data
                
                this.jsonData = this.tableDataUsers.dataSource.map(v => ({
                name: v.name,
                surname: v.surname,
                contactNumber: v.contactNumber,
                email: v.email,
                role: v.role,
                lastLoginDate: v.lastLoginDate === null ? '' : new Date(v.lastLoginDate).toLocaleDateString('en-ZA')
                }))
                
                this.tableDataUsers.isLoading = false
                this.state  = 'initialize'
            })
            .catch( () => {
                this.tableDataUsers.isLoading = false
            })
        },
        selectChecklist(checklist){
            this.selectedScheduledChecklist.checkList = checklist.description
            this.selectedScheduledChecklist.checkListId = checklist.checkListId
            this.closeChecklistSearchModal()
        },
        searchChecklistResults(){
            this.tableData.isLoading = true
            
            let request = []
            request.push({"key":"Disabled","value": false})
            if(this.searchChecklist.description !== null){
                request.push({"key":"description","value": this.searchChecklist.description})
            }
            if(this.searchChecklist.dateCreatedFrom !== null){
                request.push({"key":"createdDateFrom","value": this.searchChecklist.dateCreatedFrom})
            }
            if(this.searchChecklist.dateCreatedTo !== null){
                request.push({"key":"createdDateTo","value": this.searchChecklist.dateCreatedTo})
            }
            if(this.searchChecklist.typeId !== null){
                request.push({"key":"fkcheckListTypeid","value": this.searchChecklist.typeId})
            }

            this.$store.commit('setChecklistSearchRequest', request)
            this.searchChecklists()
            .then((res) => {
                this.tableData.dataSource = res.data
                this.tableData.isLoading = false
            })
            .catch(() => {
                this.tableData.isLoading = false
            })
        },
        selectAll(){
            //console.log(this.selectedScheduledChecklist.scheduleForItems)
            //console.log(this.companies)
            this.selectedScheduledChecklist.scheduleForItems = []

            for (let i = 0; i < this.companies.length; i++) {
                const element = this.companies[i];
                this.selectedScheduledChecklist.scheduleForItems.push(element)
            }

            //console.log(this.selectedScheduledChecklist.scheduleForItems)
        },
        closeChecklistSearchModal(){
            this.$bvModal.hide('search-checklist-modal')
        },
        clearChecklistFilter(){
            this.searchChecklist = {
                description: null,
                dateCreatedFrom: null,
                dateCreatedTo: null,
                typeId: null
            }
        },
        openChecklistSearchModal(){
            this.getChecklistTypes()
            .then((res) => {
                this.checklistTypes = res.data
                this.$bvModal.show('search-checklist-modal')
                this.searchChecklistResults()
            })
            .catch(() => {
                this.state = 'show'
            })
        }
    },
    computed:{
        ...mapState(['selectedScheduledChecklist']),    
        userRows() {
            return this.tableDataUsers.dataSource.length
        },
        scheduledForTableDataRows() {
            return this.scheduledForTableData.dataSource.length
        },
        rows()
        {
            return this.tableData.dataSource.length
        }
    }
}
</script>
